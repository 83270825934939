import { StarRating, StarRatingWithNumber } from "../shared/Review/StarRatings";
import { Galleria } from 'primereact/galleria';
import Gallery from 'react-grid-gallery';

import { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router-dom'

import { getOneReview } from "../shared/util/graphQLHandler";
import { LoadingSpinner } from "../shared/loading";
import { Button } from "primereact/button";
import { getPrimaryImageUrl, listBlogImages } from "../shared/util/storageHandler";
import { PhotoGallery } from "./PhotoGallery";
import { isMobile } from "react-device-detect";


const BlogPost = (props) => {

    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [imagesLoading, setimagesLoading] = useState(true)
    const [review, setReview] = useState({})
    const [images, setImages] = useState([])

    const fetchReviewDetails = async () => {
        let resp = await getOneReview(id)
        console.log('data', resp)
        if (resp.data !== undefined) {
            setReview(resp.data.getReview)
            setLoading(false)
        }
    }

    const fetchImages = async () => {
        let primaryImageUrl = await getPrimaryImageUrl(id)
        let imageUrlList = await listBlogImages(id)
        let allImages = [primaryImageUrl, ...imageUrlList]

        allImages = allImages.filter(function (element) {
            return element !== undefined;
        });

        // console.log('images', allImages)
        setImages(allImages)
        setimagesLoading(false)

    }


    useEffect(() => {
        fetchReviewDetails()
        fetchImages()
    }, [])

    return (
        <Fragment>
            {(loading !== true && imagesLoading !== true) ?
                <div className={`${(isMobile) ? 'blog-post-section mobile' : 'blog-post-section'}`}>
                    {/* <h2 className='title'>{review.place.name}</h2> */}
                    {/* <Divider/> */}

                    {(isMobile) ?
                        <Fragment>
                            {images.slice(0,1).map((img) => {
                                return (
                                    <img 
                                        src={img} 
                                        alt={img} 
                                        style={{maxWidth: '100%'}}
                                    />
                                )
                            })}
                        </Fragment>
                        :
                        <div className='photo-section'>
                            <PhotoGallery images={images} />
                        </div>
                    }

                    <div className='title-section'>
                        <div className='title'>
                            {review.place.name}
                        </div>
                    </div>
                    <div className='detail-section'>
                        <div className='rating-section'>
                            <div className='overall-rating'>
                                <div className='rating'><p className='label'>OVERALL</p> <div className='metric'>{review.rating.overall.toFixed(1)}</div>{StarRating(review.rating.overall)}</div>
                            </div>
                            <div className='star-ratings'>
                                <div className='rating'><p className='label'>FOOD</p> {StarRatingWithNumber(review.rating.food)}</div>
                                <div className='rating'><p className='label'>VALUE</p> {StarRatingWithNumber(review.rating.value)}</div>
                                <div className='rating'><p className='label'>EXPERIENCE</p> {StarRatingWithNumber(review.rating.exp)}</div>
                            </div>
                        </div>
                    </div>

                    {/* <Divider style={{backgroundColor:'#000'}}/> */}
                    <div className='tag-section'>
                        <p className='label'>TAGS</p>
                        {review.tags.map((tag) => {
                            return (
                                <div className='tag' key={tag}>{tag.toUpperCase()}</div>
                            )
                        })}
                    </div>
                    <div className='author-section'>
                        {review.author !== null ?
                            <Fragment>
                                {review.author.map((person) => {
                                    if (person !== "") {
                                        return (<div key={`authorIcon_${person}`} className='author-icon'>{person[0].toUpperCase()}</div>)
                                    } else {
                                        return <Fragment />
                                    }
                                })}
                            </Fragment> :
                            <Fragment />
                        }
                    </div>
                    {/* <Divider/> */}
                    <div className='comments-section'>
                        <p className='label'>REVIEW</p>
                        <div style={{ margin: '25px', textAlign: 'middle' }}><td dangerouslySetInnerHTML={{ __html: review.comments }} /></div>
                    </div>
                    <Button
                        label='Edit'
                        onClickCapture={() => { window.location = `/reviews/edit/${id}`; }}
                    />
                </div>
                : <LoadingSpinner />
            }
        </Fragment>
    )
}

export default BlogPost;