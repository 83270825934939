import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as filledStarIcon } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfAlt as halfStarIcon } from "@fortawesome/free-solid-svg-icons";
import { faStar as emptyStarIcon } from "@fortawesome/free-regular-svg-icons";


export const StarRating = (score) =>{
      // Calculate the number of stars to show for a particular score

      const filledStar = <FontAwesomeIcon icon={filledStarIcon} />
      const partialStar = <FontAwesomeIcon icon={halfStarIcon} />
      const emptyStar = <FontAwesomeIcon icon={emptyStarIcon} />
  
      const filledStarCount = Math.floor(score)
      const usePartialStar = (score % 1) >= 0.5
  
      let stars = []
  
      for (var i = 0; i < filledStarCount; i++) {
          stars.push(filledStar)
      }
  
      if (usePartialStar) {
          stars.push(partialStar)
      }
  
      const emptyStarCount = 5 - stars.length
  
      for (var k = 0; k < emptyStarCount; k++) {
          stars.push(emptyStar)
      }
  
      return (
          <div className='score'>
              <div className='stars'>{stars.map((star) => {
                  return <div style={{ display: 'inline-block' }} key={Math.random()}>{star}</div>
              })}
              </div>
          </div>
      )
}

export const StarRatingWithNumber = (score) => {
    // Calculate the number of stars to show for a particular score

    const filledStar = <FontAwesomeIcon icon={filledStarIcon} />
    const partialStar = <FontAwesomeIcon icon={halfStarIcon} />
    const emptyStar = <FontAwesomeIcon icon={emptyStarIcon} />

    const filledStarCount = Math.floor(score)
    const usePartialStar = (score % 1) >= 0.5

    let stars = []

    for (var i = 0; i < filledStarCount; i++) {
        stars.push(filledStar)
    }

    if (usePartialStar) {
        stars.push(partialStar)
    }

    const emptyStarCount = 5 - stars.length

    for (var k = 0; k < emptyStarCount; k++) {
        stars.push(emptyStar)
    }

    return (
        <div className='score'>
            <div className='stars'>{stars.map((star) => {
                return <div style={{ display: 'inline-block' }} key={Math.random()}>{star}</div>
            })}
            </div>
            <div className='number'>{(Math.round(score * 100) / 100).toFixed(1)} / 5</div>
        </div>
    )
}

