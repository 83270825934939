/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://rdtaya7rebavdg2jqpstnfmiw4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-b6bdnqxq3jfnzjaxfjgwkwy574",
    "aws_cognito_identity_pool_id": "us-east-1:8ec2722c-d14c-435b-8421-84e35564ae92",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7CVSLVAZX",
    "aws_user_pools_web_client_id": "62s97k5qt0d91vqv2j3gdgic4o",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bearygoodeats-storage-e39a70ec212232-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
