import BlogPostForm from "./BlogPostForm";

import './blog.css'

const NewBlogPage = () => {

    return (
        <div >
           <BlogPostForm editMode={false} />
        </div>
    )


}

export default NewBlogPage;