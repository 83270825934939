import { Menubar } from 'primereact/menubar';
import logo from '../../assets/logo_banner.jpg'


const menuItems = [
    // {
    //     label: 'bearygoodeats',
    //     style:{fontSize: '100px'},
    //     url:'/'
    // },

    {
        label: 'FOOD',
        // icon: 'pi pi-fw pi-file',
        items:[
            {
                label: 'Reviews',
                icon: 'pi pi-fw pi-search',
                url:'/reviews'
            },
            {
                label: 'Map',
                icon: 'pi pi-fw pi-map',
                url:'/map',
                items:[
                    {
                        label: 'BGE X NYC',
                        // icon: 'pi pi-fw pi-map',
                        url:'/map/NYC',
                    },
                    {
                        label: 'BGE X SF',
                        // icon: 'pi pi-fw pi-map',
                        url:'/map/SF',
                    },
                    {
                        label: 'BGE X Chicago',
                        // icon: 'pi pi-fw pi-map',
                        url:'/map/CHI',
                    },
                ]
            },
            {
                label: 'Add',
                icon: 'pi pi-fw pi-plus-circle',
                url:'/new'
            },
        ]
    },
    // {
    //     label: 'bearygoodcreations',
    //     url:'/comingsoon'
    // },
    {
        label: 'CREATIONS',
        url:'/comingsoon'
    },
    {
        label: 'ABOUT',
        url:'/about'
    },
    {
        label: 'SOCIALS',
        url:'/home'
    },


];

const Header = () => {

    return (
        <Menubar
            model={menuItems}
            // start={<img alt="kjlee_logo" src={logo} style={{height:'50px'}}></img>}
            start={<div><a className='logo' href='/'> bearygoodeats</a></div>}
            // end={<div style={{marginRight: '25px'}}><a href='https://www.instagram.com/bearygood.eats/'>@bearygood.eats</a></div>}
        />
    )



}

export default Header;