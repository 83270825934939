import './blog.css'
import BlogCard from "./BlogCard";

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';


import { getReviews } from '../shared/util/graphQLHandler'
import { reviewList } from "../shared/Review/review";
import { Fragment, useEffect, useState } from "react";
import { LoadingSpinner } from '../shared/loading';
import { getPrimaryImageUrlDict } from '../shared/util/storageHandler';
import { isMobile } from 'react-device-detect';

const sortFieldOptions = [
    { value: 'overall', label: 'Overall Rating' },
    { value: 'food', label: 'Food Rating' },
    { value: 'updateAt', label: 'Last Updated' },
    { value: 'createdAt', label: 'Created Date' },
    { value: 'name', label: 'Name' },
]

const sortDirectionOptions = [
    { value: 'asc', label: 'ASC' },
    { value: 'desc', label: 'DESC' },
]

const BlogPage = () => {

    const [loading, setLoading] = useState(true)
    const [reviewList, setReviewList] = useState([])
    const [imageDict, setImageDict] = useState({})
    const [sortField, setSortField] = useState("createdAt")
    const [sortDirection, setSortDirection] = useState('asc')

    useEffect(() => {
        fetchAllReviews()
        fetchReviewImages()
        // console.log('loading', loading)
    }, [])

    useEffect(() => {
        sortReviews()
        // console.log('loading', loading)
    }, [sortField, sortDirection])

    const fetchAllReviews = async () => {
        // let reviews = await getReviews()
        let reviewResp = await getReviews()
        let reviews = reviewResp.data.listReviews.items
        console.log('fetched', reviews)
        reviews.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)

        setReviewList(reviews)
        setLoading(false)
        // console.log('list', reviews.data.listReviews.items)
    }

    const sortReviews = () => {
        let reviews = reviewList.slice()
        console.log('sort', sortField)
        console.log('direction', sortDirection)



        let sortDirection_1 = (sortDirection === 'asc') ? -1 : 1
        let sortDirection_2 = (sortDirection === 'desc') ? 1 : -1

        // console.log(reviews[0])

        switch (sortField) {
            case 'overall':
                reviews.sort((a, b) => {return a.rating.overall - b.rating.overall})
            case 'food':
                reviews.sort((a, b) => {return a.rating.food - b.rating.food})
            case 'updatedAt':
                reviews.sort((a, b) => (a.updatedAt > b.updatedAt) ? sortDirection_1 : sortDirection_2)
            case 'createdAt':
                reviews.sort((a, b) => (a.createdAt > b.createdAt) ? sortDirection_1 : sortDirection_2)
            case 'name':
                reviews.sort((a, b) => (a.title > b.title) ? sortDirection_1 : sortDirection_2)
            default:
                break
        }

        let overall = []
        for (var rev of reviews) {
            overall.push(rev.rating.overall)
        }

        let food = []
        for (var rev of reviews) {
            food.push(rev.rating.food)
        }

        console.log('ov', overall)
        console.log('food', food)

        if (sortDirection === 'asc') {
            setReviewList(reviews)
        } else {
            setReviewList(reviews)
        }

    }


    const fetchReviewImages = async () => {
        // let reviews = await getReviews()
        let imageDict = await getPrimaryImageUrlDict()
        setImageDict(imageDict)
        setLoading(false)
    }

    return (
        <div className={`${(isMobile) ? 'content mobile' : 'content'}`}>
            <h2 className='page-title'>bearygoodreviews</h2>
            <div className='filters-section'>
                <div className='label'>
                    Sort By:
                </div>
                <Dropdown
                    className='filter-selector'
                    value={sortField}
                    options={sortFieldOptions}
                    onChange={(e) => setSortField(e.value)} placeholder="Sort"
                />

                <SelectButton
                    className='direction-selector'
                    value={sortDirection}
                    options={sortDirectionOptions}
                    onChange={(e) => setSortDirection(e.value)}
                    style={{ display: 'inline-block', marginLeft: '20px' }}
                />


            </div>
            {(loading === false) && reviewList && sortDirection ?
                <div className={`${(isMobile) ? 'blog-post-container mobile' : 'blog-post-container'}`}>
                    {reviewList.map((review) => {
                        return (<BlogCard review={review} imageDict={imageDict} />)
                    })}
                </div>
                : <LoadingSpinner />
            }
        </div>
    )


}

export default BlogPage;