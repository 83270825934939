import { classNames } from "primereact/utils"
import { isMobile } from "react-device-detect"
import BlogCard from "../blog/BlogCard"
import { StarRatingWithNumber } from "../shared/Review/StarRatings"

const MarkerInfo = (props) => {

    const selectedMarker = props.selectedMarker
    const review = props.review
    const imageDict = props.imageDict

    return (
        <div id='info' key={`${selectedMarker}_info`} className={`${(isMobile) ? 'mobile' : ''}`}>
            <BlogCard review={review} imageDict={imageDict}/>
        </div>
        // <div id='info' key={`${selectedMarker}_info`}>
        //     <h2 className='title'>{selectedMarker}</h2>
        //     {/* <Divider/> */}
        //     <div className='ratings-section'>
        //         <p className='label'>OVERALL</p> {StarRatingWithNumber(review.rating.overall)}
        //         <br />
        //         <p className='label'>FOOD</p> {StarRatingWithNumber(review.rating.food)}
        //         <br />
        //         <p className='label'>VALUE</p> {StarRatingWithNumber(review.rating.value)}
        //         <br />
        //         <p className='label'>EXPERIENCE</p> {StarRatingWithNumber(review.rating.exp)}
        //     </div>
        //     {/* <Divider style={{backgroundColor:'#000'}}/> */}
        //     <div className='tag-section'>
        //         <p className='label'>TAGS</p>
        //         {review.tags.map((tag) => {
        //             return (
        //                 <div className='tag' key={tag}>{tag.toUpperCase()}</div>
        //             )
        //         })}
        //     </div>
        //     {/* <Divider/> */}
        //     <div className='comments-section'>
        //        <a href='/blog'>See Full Review</a>
        //     </div>
        // </div>
    )
}

export default MarkerInfo;
