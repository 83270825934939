import { Amplify, API } from 'aws-amplify'
import awsconfig from "../../../aws-exports";
// import { listReviews } from '../../../graphql/queries'
import { createReview, updateReview } from '../../../graphql/mutations'
import { customGetReview, customListReviews} from '../../../graphql/customQueries'

Amplify.configure(awsconfig);

// Query using a parameter
export const getOneReview = async(id) =>{
    const queryTestResult = await API.graphql({ 
        query: customGetReview, 
        variables: {id: id},
        authMode: 'API_KEY'
    });

    return queryTestResult
}


export const getReviews = async() =>{
    const queryTestResult = await API.graphql({ 
        query: customListReviews, 
        authMode: 'API_KEY'
    });

    return queryTestResult
}


export const createNewReview = async(inputValues) =>{
    const createReviewResult = await API.graphql({ 
        query: createReview, 
        variables: {input: inputValues},
        authMode: 'API_KEY'
    });

    console.log(createReviewResult)

    return createReviewResult
}


export const editReview = async(inputValues) =>{
    const editReviewResult = await API.graphql({ 
        query: updateReview, 
        variables: {input: inputValues},
        authMode: 'API_KEY'
    });

    console.log(editReviewResult)

    return editReviewResult
}