import { useParams } from 'react-router-dom'
import { GMap } from 'primereact/gmap';
import { useState, useEffect, Fragment } from 'react';
import { getReviews } from '../shared/util/graphQLHandler';
import { loadGoogleMaps, removeGoogleMaps } from '../shared/util/googleAPI';
import { LoadingSpinner } from '../shared/loading';
// import { reviewList } from '../shared/Review/review';
import MarkerInfo from './MarkerInfo'


import './map.css'
import { MAPS_API_KEY } from '../shared/util/constants';
import { fetchPrimaryImageUrlDict, getPrimaryImageUrlDict } from '../shared/util/storageHandler';

const cityCenters = {
    NYC: { lat: 40.73150838754362, lng: -73.97784803442703 },
    SF: { lat: 37.74662806460183, lng: -122.42635511212455 },
    CHI: { lat: 41.88278431167697, lng:  -87.62811836628796 },
}


const MapPage = (props) => {
    const { city } = useParams()

    const [mapCenter, setMapCenter] = useState(cityCenters['SF'])

    const [loading, setLoading] = useState(true)
    const [googleMapsReady, setGoogleMapsReady] = useState(false);

    const [overlays, setOverlays] = useState([])
    const [selectedMarker, setSelectedMarker] = useState("Tsurutontan")

    // const [map, setMap] = useState(reviewList[0])
    const [reviewList, setReviewList] = useState([])
    const [review, setReview] = useState(null)
    const [imageDict, setImageDict] = useState({})


    const [showInfoScreen, setShowInfoScreen] = useState(false)

    useEffect(() => {
        fetchAllReviews()
        fetchReviewImages()
    }, [])

    useEffect(() => {
        // console.log('city', city)
        // console.log(city.split('CXY'))
        if ((city !== undefined && city !== "") && city in cityCenters) {
            setMapCenter(cityCenters[city])
        } else if (String(city).includes('CXY')) {
            let lat = parseFloat(city.split('CXY')[1])
            let lng = parseFloat(city.split('CXY')[2])
            setMapCenter({ lat: lat, lng: lng })
        } else {
            setMapCenter(cityCenters['NYC'])
        }

    }, [city])


    const fetchAllReviews = async () => {
        // let reviews = await getReviews()
        let reviews = await getReviews()
        setReviewList(reviews.data.listReviews.items)
        setLoading(false)
    }

    const fetchReviewImages = async () => {
        // let reviews = await getReviews()
        let imageDict = await getPrimaryImageUrlDict()
        setImageDict(imageDict)
        setLoading(false)
    }

    useEffect(() => {
        loadGoogleMaps(() => {
            setGoogleMapsReady(true);
        });

        return () => {
            removeGoogleMaps();
        }
    }, []);

    const loadMarkers = () => {
        let google = window.google

        let overlays = []
        for (var reviewItem of reviewList) {
            let newMarker = new google.maps.Marker({
                key: `${reviewItem.place.name.replace(" ", "_")}`,
                position: { lat: reviewItem.place.lat, lng: reviewItem.place.lng },
                title: reviewItem.place.name,
            })
            overlays.push(newMarker)
        }
        setOverlays(overlays)
    }

    const onOverlayClick = (event) => {
        //event: MouseEvent of Google Maps api
        let marker = event.overlay.title.replace("_", " ")
        setSelectedMarker(marker)

        var review = reviewList.find(obj => {
            return obj.place.name === marker
        })
        setReview(review)
        // console.log(marker, review)
        setShowInfoScreen(true)
    }

    const onMapReady = (map) => {
        loadMarkers()
    }

    return (
        <Fragment>
            <script src={`https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&libraries=geometry`}></script>
            {(googleMapsReady === true && loading === false) ?
                <div>
                    <GMap
                        key={`map`}
                        options={{ center: mapCenter, zoom: 14 }}
                        overlays={overlays}
                        // onMapClick={onMapClick}
                        onMapReady={onMapReady}
                        onOverlayClick={onOverlayClick}
                        style={{ width: '100vw', height: 'calc(100vh - 75px)', minHeight: '320px' }}
                    />
                    {(showInfoScreen === true && selectedMarker !== "1") ?
                        <MarkerInfo
                            key={`Info_${selectedMarker}`}
                            review={review}
                            selectedMarker={selectedMarker}
                            imageDict={imageDict}
                        /> : <div />
                    }

                </div> :
                <LoadingSpinner />
            }
        </Fragment>
    )
}

export default MapPage;