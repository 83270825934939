
export const ratings = {
    overall: 4.6,
    food: 5.0,
    value: 4.0,
    exp: 4.0
}

export const place = {
    name: "Tsurutontan",
    address: "21 e 16th st, new york, ny 10003",
    lat: 40.73704,
    lng: -73.99124
}

export const tags = ['soba', 'udon', 'japanese']

export const review = {
    place: place,
    ratings: ratings,
    comments: "It was pretty good",
    tags: tags
}

export const reviewList = [
    review,
    {
        place: {
            name: 'Kenka',
            lat: 40.72929323561573,
            lng: -73.98848685299934
        },
        ratings: {
            overall: 4.25,
            food: 4.0,
            value: 5,
            exp: 4.0
        },
        comments: "It's been better",
        tags: ['japanese', 'bar']
    },
    {
        place: {
            name: 'Red Rooster',
            lat: 40.81400063001567,
            lng: -73.9436081459193
        },
        ratings: {
            overall: 4.25,
            food: 4.5,
            value: 3.5,
            exp: 4.5
        },
        comments: "Vibes were nice",
        tags: ['soul']
    }
]