
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";

import HomePage from "./components/home/HomePage.js";
import MapPage from "./components/map/MapPage.js";
import BlogPage from "./components/blog/BlogPage.js";
import BlogPost from "./components/blog/BlogPost.js";
import NewBlogPage from "./components/blog/NewBlogPage.js";
import EditBlogPage from "./components/blog/EditBlogPage.js";
import AboutPage from "./components/about/AboutPage.js"

import ComingSoon from "./components/misc/ComingSoon"
// const HomePage =  React.lazy(() => import("./components/home/HomePage.js"));
// const MapPage =  React.lazy(() => import("./components/map/MapPage.js"));
// const BlogPage =  React.lazy(() => import("./components/blog/BlogPage.js"));

export default function AppRoutes() {
    return (
        <Router>
            <Suspense fallback={<div>404</div>}>
                <Routes>
                    {/* Home */}
                    <Route exact path={"/home"} element={<HomePage/>} />
                    {/* Map */}
                    <Route exact path={"/map"} element={<MapPage/>} />
                    <Route exact path={"/map/:city"} element={<MapPage/>} />
                    {/* Blog */}
                    <Route exact path={"/new"} element={<NewBlogPage/>} />
                    <Route exact path={"/reviews"} element={<BlogPage/>} />
                    <Route exact path={"/reviews/:id"} element={<BlogPost/>} />
                    <Route exact path={"/reviews/edit/:id/"} element={<EditBlogPage />} />
                    {/* About */}
                    <Route exact path={"/about"} element={<AboutPage/>} />
                    {/* Other */}
                    <Route exact path={"/comingsoon"} element={<ComingSoon/>} />
                    <Route exact path={"/"} element={<HomePage/>} />
                    {/* 404 */}
                    {/* <Route exact path="/*" component={HomePage} /> */}
                </Routes>
            </Suspense>
        </Router>
    );
}
