import { CUSTOM_COLOR_BROWN } from "../shared/util/constants"

const ComingSoon = () => {
    return (
        <div
            style={{
                color: '#FFF',
                backgroundColor: CUSTOM_COLOR_BROWN,
                borderRadius: '100px',
                fontFamily: 'Nunito',
                fontSize: '100px',
                fontWeight: 'bolder',
                letterSpacing: '5px',
                margin: '100px',
                padding: '50px',
                width: '50%',
                marginLeft:'auto',
                marginRight:'auto',
            }}
        
        > Coming Soon! </div>
    )
}

export default ComingSoon