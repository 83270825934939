import BlogPostForm from "./BlogPostForm";

import './blog.css'

const EditBlogPage = () => {

    return (
        <div >
           <BlogPostForm editMode={true}/>
        </div>
    )


}

export default EditBlogPage;