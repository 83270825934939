import { Storage } from "aws-amplify";

export const getExtension = (filename) => {
    return filename.split('.').pop();
}

export const testStorage = async() =>{
    let result = await Storage.put("test.txt", "Hello");
    return result
}

const uploadToS3 = async(key, file) =>{
    let result = await Storage.put(key, file);
    console.log(result)
}


export const uploadBlogPostImage = async(reviewId, filename, file) => {
    console.log('to upload', filename)
    let key = 'assets/images/' + String(reviewId) + '/' + String(filename)
    await uploadToS3(key, file)
}

export const uploadPrimaryBlogPostImage = async(reviewId, file) => {
    let key = 'primary/' + String(reviewId) + '/primary.' + getExtension(file.name)
    await uploadToS3(key, file)
}

export const getPrimaryImageUrl = async(reviewId) =>{
    let prefix =   'primary/' + String(reviewId) + '/primary'
    let result = await Storage.list(prefix);
    
    if (result.length > 0 ) {
        let key = result[0].key
        let imageUrl = await getImageUrlbyKey(key)
        return imageUrl
    }



}


export const listBlogImages = async(reviewId) =>{
    console.log(reviewId)
    let prefix =  'assets/images/' + String(reviewId) + '/'
    let result = await Storage.list(prefix);
    let imageUrls = []
    if (result.length > 0) {
        for (var item of result) {
            let key = item.key
            let imageUrl = await getImageUrlbyKey(key)
            imageUrls.push(imageUrl)
        }
    }
    return imageUrls
}


export const getImageUrlbyKey = async (key) =>{
    const imageUrl = await Storage.get(key, { level: 'PUBLIC' });
    return imageUrl
}

export const getPrimaryImageUrlDict = async(reviewId) =>{
    let prefix = 'primary/'
    let resultList = await Storage.list(prefix);

    let imageDict = {}

    let imageUrl

    let idList = []
    let jobList = []
    // console.log(resultList)
    if (resultList.length > 0) {
        for (var result of resultList) {
            let key = result.key
            let reviewId = key.split('/')[1]
            idList.push(reviewId)
            // imageUrl = await getImageUrlbyKey(key)
            jobList.push(getImageUrlbyKey(key))
            // imageDict[reviewId] = imageUrl
        }
    }

    let urlResultList = await Promise.all(jobList)

    for (var i = 0; i < idList.length; i+= 1) {
        imageDict[idList[i]] = urlResultList[i]
    }



    // console.log(imageDict)
    return imageDict
}



