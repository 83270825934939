
import './home.css'

import {isMobile} from 'react-device-detect';

import { useState, useEffect } from 'react'
import { getReviews } from '../shared/util/graphQLHandler'
import { LoadingSpinner } from '../shared/loading'
import BlogCard from '../blog/BlogCard'
import { getPrimaryImageUrlDict } from '../shared/util/storageHandler'


import bannerImage from "../../assets/logo_banner.jpg"
import samAvatar from "../../assets/samAvatar.jpg"
import aliceAvatar from "../../assets/aliceAvatar.jpg"

const HomePage = () => {

    const [loading, setLoading] = useState(true)
    const [imagesLoading, setimagesLoading] = useState(true)
    const [reviewList, setReviewList] = useState([])
    const [imageDict, setImageDict] = useState({})

    useEffect(() => {
        fetchAllReviews()
        fetchReviewImages()
        console.log('loading', loading)
    }, [])

    const fetchAllReviews = async () => {
        // let reviews = await getReviews()
        let reviewResp = await getReviews()
        let reviews = reviewResp.data.listReviews.items
        console.log(reviews)
        reviews.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)
        setReviewList(reviews)
        setLoading(false)
        
        // console.log('list', reviews.data.listReviews.items)
    }

    const fetchReviewImages = async () => {
        // let reviews = await getReviews()
        let imageDict = await getPrimaryImageUrlDict()
        setImageDict(imageDict)
        setimagesLoading(false)
    }

    return (
        <div className='home-page'>
            <div className={`${(isMobile) ? 'banner mobile' : 'banner'}`}>
                
                <div className='site-title'>
                    bearygoodeats
                    <div className='subtitle'>
                        by sam and alice
                    </div>
                    </div>
                <div className={`${(isMobile) ? 'home-avatars mobile' : 'home-avatars'}`}>
                    <img src={samAvatar} />
                    <img src={aliceAvatar} />
                </div>
            </div>
            <div className={`${(isMobile) ? 'content mobile' : 'content'}`}>
                {/* <h2 className='page-title'>bearygoodreviews</h2> */}
                {(loading === false && imagesLoading === false) ?
                    <div className={`${(isMobile) ? 'blog-card-container mobile' : 'blog-card-container'}`}>
                        {reviewList.map((review) => {
                            return (<BlogCard review={review} imageDict={imageDict}/>)
                        })}
                    </div>
                    : <LoadingSpinner/>
                }
        </div>
        </div>
    )


}

export default HomePage;