import { useRef, useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Editor } from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import sha256 from 'js-sha256';
import { loadGooglePlaces, removeGoogleMaps } from '../shared/util/googleAPI';
import { createNewReview, editReview, getOneReview } from '../shared/util/graphQLHandler';
import { getExtension, getPrimaryImage, getPrimaryImageUrl, listBlogImages, testStorage, uploadBlogPostImage, uploadPrimaryBlogPostImage } from '../shared/util/storageHandler';

import { CUSTOM_COLOR_GREEN } from '../shared/util/constants';
import { LoadingSpinner } from '../shared/loading';
import { isMobile } from 'react-device-detect';

const commentTemplate = () => {
    return (
        "<h2>Backstory</h2><p><br></p><p>Hello, we went to a place!</p><p><br></p><p><br></p><h2><strong>What we ordered</strong></h2><h2><br></h2><h2><br></h2><h2><strong>Final thoughts</strong></h2><p><br></p><p>I would go again!</p><p><br></p>"
    )
}

const BlogPostForm = (props) => {

    const editMode = false || props.editMode

    const { id } = useParams();
    const [reviewId, setReviewId] = useState("")
    const [review, setReview] = useState({})
    const [loading, setLoading] = useState(true)

    const [googleMapsReady, setGoogleMapsReady] = useState(false)
    const [title, setTitle] = useState("")
    const placeInputRef = useRef(null);

    const [address, setAddress] = useState('')
    const [latlng, setLatLng] = useState({})
    const [rating, setRating] = useState({ food: 0, value: 0, exp: 0, overall: 0 })
    const [authorInput, setAuthorInput] = useState('')
    const [author, setAuthor] = useState([])
    const [description, setDescription] = useState("")
    const [comment, setComment] = useState(commentTemplate)
    const [tagInput, setTagInput] = useState('')
    const [tags, setTags] = useState([])
    const [password, setPassword] = useState("")

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState()

    const [primaryImage, setPrimaryImage] = useState([])
    const [images, setImages] = useState([])

    const hideAll = () => {
        setShowConfirmationDialog(false)
    }

    const verify = 'c539372bc015b9daf6d29928c86484650d316314b8d43db199ac09aaae0be839'

    const fetchReviewDetails = async () => {
        let resp = await getOneReview(id)

        if (resp.data !== undefined) {
            let rev = resp.data.getReview

            setReview(rev)
            setReviewId(rev.id)
            setTitle(rev.place.name)
            setAddress(rev.place.address)
            setLatLng({ lat: rev.place.lat, lng: rev.place.lng })
            setAuthorInput(rev.author !== null ? rev.author.join(";") : "")
            setAuthor(rev.author !== null ? rev.author : [])
            setRating(rev.rating)
            setDescription(rev.description !== null ? rev.description : "")
            setComment(rev.comments)
            setTagInput(rev.tags !== null ? rev.tags.join(";") : "")
            setTags(rev.tags !== null ? rev.tags : [])
            setLoading(false)
        }
    }

    const fetchImages = async () => {
        let imageUrlList = await listBlogImages(id)
        console.log('images', imageUrlList)
        setImages(imageUrlList)
    }

    const fetchPrimaryImage = async () => {
        let primaryImageUrl = await getPrimaryImageUrl(id)
        console.log(primaryImageUrl)
        setPrimaryImage(primaryImageUrl)
    }

    // Check if using existing review
    useEffect(() => {
        if (editMode) {
            fetchReviewDetails()
            fetchPrimaryImage()
            fetchImages()
        } else {
            setLoading(false)
        }

    }, [editMode])



    // Check if submit button should be enabled
    useEffect(() => {
        let status = false;

        if (title === "" || address === "" || latlng === undefined || description === "") {
            status = true
        } else if (rating.food === 0 || rating.value === 0 || rating.exp === 0) {
            status = true
        } else if (sha256(password) !== verify) {
            status = true
        }

        setSubmitButtonDisabled(status)
    }, [title, address, latlng, description, rating, password])



    // initialize the google place autocomplete
    useEffect(() => {
        if (!editMode) {
            loadGooglePlaces(() => {
                setGoogleMapsReady(true);
            });

            return () => {
                removeGoogleMaps();
            }
        }
    }, [editMode]);

    // Google Place API
    useEffect(() => {
        if (googleMapsReady) {
            initPlaceAPI()
        }
    })

    const initPlaceAPI = () => {
        let google = window.google

        if (google !== undefined) {
            let autocomplete = new google.maps.places.Autocomplete(
                placeInputRef.current,
                {
                    types: ["geocode"],
                }
            );
            autocomplete.setFields(["address_component", 'geometry']);
            new window.google.maps.event.addListener(
                autocomplete,
                "place_changed",
                () => {
                    let place = autocomplete.getPlace();
                    console.log(place)
                    console.log(place.address_components)
                    console.log(place.geometry.location.lat())
                    console.log(place.geometry.location.lng())

                    let address = place.address_components
                    let addressString = `${address[0].long_name} ${address[1].short_name}, ${address[3].long_name}, ${address[5].short_name} ${address[7].long_name}`

                    setAddress(addressString)
                    setLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
                }
            );
        }
    };

    // Text Box Change
    const handleCommentChange = (e) => {
        // console.log(e.htmlValue)
        setComment(e.htmlValue)
    }

    // Rating changes
    const handleRatingUpdate = (e, field) => {
        let updatedRating = { ...rating }
        updatedRating[field] = e.value
        updatedRating['overall'] = (3 * updatedRating.food + updatedRating.value + updatedRating.exp) / 25 * 5
        setRating(updatedRating)
    }

    // Author Badges
    const updateAuthors = (e) => {
        let authorString = e.target.value
        setAuthorInput(authorString)

        let authorList = authorString.split(';')

        let uniqueAuthorList = [...new Set(authorList)]
        setAuthor(uniqueAuthorList)

    }

    // Tag Buttons
    const updateTags = (e) => {
        let tagString = e.target.value
        setTagInput(tagString)

        let tagList = tagString.split(';')
        let uniqueTagList = [...new Set(tagList)]
        
        let finalTagList = uniqueTagList.map((item) => {
            return item.toUpperCase().trim()
        })
        setTags(finalTagList)

    }

    // Image upload

    // Creates Graphql Params
    const createInputs = () => {
        return ({
            title: title,
            type: 'food',
            place: {
                address: address,
                lat: latlng.lat,
                lng: latlng.lng,
                name: title
            },
            rating: {
                exp: rating.exp,
                food: rating.food,
                overall: rating.overall,
                value: rating.value
            },
            tags: tags,
            description: description,
            author: author,
            comments: comment
        })
    }

    const handleSubmitReview = async (e) => {
        e.preventDefault()

        let inputs = createInputs()
        console.log('input', inputs)

        let resp
        if (editMode === true) {
            inputs.id = id
            inputs._version = review._version
            resp = await editReview(inputs)
        } else {
            resp = await createNewReview(inputs)
        }

        if (resp.data !== undefined) {
            if (editMode === true) {
                setConfirmationMessage(<p>Your review has been updated successfully!</p>)
                setReviewId(resp.data.updateReview.id)
            } else {
                setConfirmationMessage(<p>Your review has been submitted successfully!</p>)
                setReviewId(resp.data.createReview.id)
            }
        } else {
            setConfirmationMessage(<p>I think something broke: {JSON.stringify(resp)}</p>)
        }
        setShowConfirmationDialog(true)
    }

    const chooseOptions = {
        icon: "pi pi-fw pi-images",
        iconOnly: true,
        className: "custom-choose-btn p-button-rounded p-button-outlined"
    };
    const uploadOptions = {
        icon: "pi pi-fw pi-cloud-upload",
        iconOnly: true,
        className:
            "custom-upload-btn p-button-success p-button-rounded p-button-outlined"
    };
    const cancelOptions = {
        icon: "pi pi-fw pi-times",
        iconOnly: true,
        className:
            "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined"
    };

    const customUploaderPrimary = async (e) => {
        const file = e.files[0];
        await uploadPrimaryBlogPostImage(reviewId, file)

        fetchPrimaryImage()
        
    }

    const customUploader = async (e) => {
        const files = e.files;

        let i = images.length + 1
        for (var file of files) {
            let filename = `phtot_${i}.${getExtension(file.name)}`
            await uploadBlogPostImage(reviewId, filename, file)
            i += 1
        }
        fetchImages()
    }

    const goToReview = (e) => {
        e.preventDefault();
        window.location = `/reviews/${review.id}`;
    }

    const goToEdit = (e) => {
        e.preventDefault();
        window.location = `/reviews/edit/${reviewId}`;
    }

    const goToMap = (e) => {
        e.preventDefault();
        console.log('go to map', `/map/CXY${latlng.lat}CXY${latlng.lng}`)
        window.location = `/map/CXY${latlng.lat}CXY${latlng.lng}`;
    }


    return (
        <Fragment>
            {(loading !== true) ?
                <div className={`${(isMobile) ? 'content mobile' : 'content'}`}>
                    <div className={`${(isMobile) ? 'new-post-form mobile' : 'new-post-form'}`} style={{ marginTop: '10px' }}>
                        {editMode === true ? <h2>Edit Review</h2> : <h2>Create New Review</h2>}
                        <form>
                            <p className='section-header'>Name of Location</p>
                            <InputText disabled={editMode} style={{ minWidth: '100%' }} value={title} onChange={(e) => setTitle(e.target.value)} />

                            <p>Address</p>
                            <InputText ref={placeInputRef} style={{ minWidth: '100%' }} value={address} onChange={(e) => setAddress(e.target.value)} />
                            {(latlng !== undefined) ?
                                <p style={{ fontSize: '12px', color: '#555' }}>{`lat: ${latlng.lat}    long: ${latlng.lng}`}</p>
                                : <div></div>
                            }

                            <p className='section-header'>Author(s)</p>
                            <InputText style={{ minWidth: '100%' }} value={authorInput} onChange={(e) => updateAuthors(e)} />
                            {author.map((person) => {
                                if (person !== "") {
                                    return (<div key={`authorIcon_${person}`} className='author-icon'>{person[0].toUpperCase()}</div>)
                                } else {
                                    return <Fragment />
                                }
                            })}

                            <h2>Ratings</h2>
                            <div>
                                <div style={{ width: '30%', display: 'inline-block', verticalAlign: 'top' }}>
                                    <p>Food</p>
                                    <InputNumber value={rating['food']} mode="decimal" minFractionDigits={1} onChange={(e) => handleRatingUpdate(e, 'food')} />
                                    <p>Value</p>
                                    <InputNumber value={rating['value']} mode="decimal" minFractionDigits={1} onChange={(e) => handleRatingUpdate(e, 'value')} />
                                    <p>Experience</p>
                                    <InputNumber value={rating['exp']} mode="decimal" minFractionDigits={1} onChange={(e) => handleRatingUpdate(e, 'exp')} />
                                    {/* {rating.overall} */}
                                </div>
                                <div style={{ width: '70%', display: 'inline-block' }} >
                                    <div className='key-metric'>
                                        <div className='label'>Overall Score</div>
                                        <div className='score'>{rating.overall.toFixed(1)}</div>
                                    </div>
                                </div>
                            </div>

                            <p className='section-header'>Short Description</p>
                            <InputText style={{ minWidth: '100%' }} value={description} onChange={(e) => setDescription(e.target.value)} />
                            <p style={{ fontSize: '12px', color: '#555' }}>{`${description.length}/200 characters`}</p>

                            <p>Comments</p>
                            <Editor
                                value={comment}
                                onTextChange={(e) => handleCommentChange(e)}
                                style={{ minHeight: '400px' }}
                            />


                            <p>Tags</p>
                            <InputText
                                style={{ minWidth: '100%' }}
                                value={tagInput}
                                onChange={(e) => updateTags(e)}
                            />
                            <div className='tag-section'>
                                {tags.map((tag) => {
                                    return (
                                        <div className='tag' key={tag}>{tag.toUpperCase()}</div>
                                    )
                                })}
                            </div>


                            {(editMode === true) ?
                                <Fragment>
                                    <h2>Images</h2>
                                    <p>Primary</p>
                                    <FileUpload
                                        name="demo"
                                        url="./upload"
                                        mode="advanced"
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        customUpload
                                        uploadHandler={customUploaderPrimary}
                                    />
                                    {(primaryImage !== undefined) ?
                                        <img
                                            className='image-thumbnail'
                                            key={primaryImage}
                                            alt={primaryImage}
                                            src={primaryImage}
                                        /> :
                                        <Fragment />
                                    }
                                    <p>Additional</p>
                                    <FileUpload
                                        name="demo"
                                        url="./upload"
                                        mode="advanced"
                                        multiple
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        customUpload
                                        uploadHandler={customUploader}
                                    />
                                    {(images.map((imageUrl) => {
                                        return (
                                            <img
                                                className='image-thumbnail'
                                                key={imageUrl}
                                                alt={imageUrl}
                                                src={imageUrl}
                                            />
                                        )
                                    }))

                                    }
                                </Fragment>
                                : <Fragment>
                                    <h2>Images</h2>
                                    <div className='invalid-section'>
                                        <p>Please save before uploading images</p>
                                    </div>
                                </Fragment>
                            }


                            <h2>Submit</h2>
                            <p>Authorization</p>
                            <InputText
                                style={{ minWidth: '78%', marginRight: '2%', marginBottom: '10px' }}
                                value={password}
                                type='password'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                // className='p-button-info'
                                style={{ minWidth: '20%', marginBottom: '10px', color: { CUSTOM_COLOR_GREEN } }}
                                onClick={(e) => handleSubmitReview(e)}
                                label='Submit'
                                disabled={submitButtonDisabled}
                            />
                            <br />
                            <div>
                                <Button
                                    // className='p-button-info'
                                    style={{ minWidth: '20%', marginRight: '10px', color: { CUSTOM_COLOR_GREEN } }}
                                    label='Go To Review'
                                    onClickCapture={goToReview}
                                    disabled={!(reviewId !== '' && reviewId !== undefined)}
                                />
                                <Button
                                    // className='p-button-info'
                                    style={{ minWidth: '20%', color: { CUSTOM_COLOR_GREEN } }}
                                    label='Go to Map'
                                    onClickCapture={goToMap}
                                    disabled={!(reviewId !== '' && reviewId !== undefined)}
                                />
                            </div>

                        </form>
                        <br />
                        <br />
                        <br />
                        <Dialog
                            header="Confirmation"
                            footer
                            visible={showConfirmationDialog}
                            style={{ width: '50vw' }}
                            onHide={hideAll}
                        >
                            {confirmationMessage}
                            <Button
                                // className='p-button-info'
                                style={{ minWidth: '20%', marginRight: '10px', color: { CUSTOM_COLOR_GREEN } }}
                                label='See Review'
                                onClick={goToReview}
                            />
                            <Button
                                className='p-button-secondary'
                                style={{ minWidth: '20%', marginRight: '10px', color: { CUSTOM_COLOR_GREEN } }}
                                label='Add Images'
                                onClick={goToEdit}
                            />
                            <Button
                                // className='p-button-info'
                                style={{ minWidth: '20%', color: { CUSTOM_COLOR_GREEN } }}
                                label='Go to Map'
                                onClick={(e) => goToMap(e)}
                            />

                        </Dialog>
                    </div >
                </div > :
                < LoadingSpinner />
            }
        </Fragment>

    )
}


// mutation MyMutation {
//     createReview(input: {place: {address: "testPlace", lat: 40.7510667, lng: -73.975569, name: ""}, rating: {exp: 1.5, food: 1.5, overall: 1.5, value: 1.5}, tags: ""})
//   }


export default BlogPostForm;