import { Fragment, useEffect, useState } from "react"
import { Dialog } from 'primereact/dialog'


import placeholderImage from "../../assets/logo_banner.jpg"
import placeholderImage0 from "../../assets/images/placeholder_0.jpg"
import placeholderImage1 from "../../assets/images/placeholder_1.jpg"
import placeholderImage2 from "../../assets/images/placeholder_2.jpg"
import placeholderImage3 from "../../assets/images/placeholder_3.jpg"
import placeholderImage4 from "../../assets/images/placeholder_4.jpg"
import placeholderImage5 from "../../assets/images/placeholder_5.jpg"
import { Galleria } from "primereact/galleria"

const placeholderImages = [
    placeholderImage0,
    placeholderImage1,
    placeholderImage2,
    placeholderImage3,
    placeholderImage4,
    placeholderImage5
]


export const PhotoGallery = (props) => {

    let images = props.images

    const [primaryImage, setPrimaryImage] = useState()
    const [secondaryImages, setSecondaryImages] = useState([])
    let [showGalleryOverlay, setShowGalleryOverlay] = useState(false)


    useEffect(() => {
        if (images.length > 0) {
            setPrimaryImage(images[0])

            if (images.length > 1) {
                setSecondaryImages(images.slice(1, 5))
                console.log('secondary', secondaryImages)
            }
        }
    }, [])

    const hideAll = () => {
        console.log('hide')
        setShowGalleryOverlay(false)
    }

    const handleShowGallery = (e) => {
        // e.preventDefault()
        setShowGalleryOverlay(true)
    }

    const itemTemplate = (item) => {
        return (
            <img
                src={item}
                alt={'photo_thumbnail'}
                style={{
                    maxHeight: '650px',
                    padding: '20px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            />
        )
    }

    const thumbnailTemplate = (item) => {
        return (
            <img
                src={item}
                alt={'photo_thumbnail'}
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
        )
    }

    return (
        <Fragment>
            <div className='photo-gallery' onClick={(e) => handleShowGallery(e)}>
                {(images.length === 0) ?
                    <Fragment>
                        <div className='primary solo'>
                            <img className='primary' src={placeholderImage} alt='1' />
                        </div>
                    </Fragment>
                    :
                    (images.length > 0) ?
                        <Fragment>
                            {(images.length === 2) ?
                                <Fragment>
                                    <div className='primary'>
                                        <img className='primary' src={primaryImage} alt='1' />
                                    </div>
                                    <div className='primary'>
                                        <img className='primary' src={secondaryImages[0]} alt='2' />
                                    </div>
                                    
                                </Fragment>
                                : <Fragment>
                                    <div className='primary'>
                                        <img className='primary' src={primaryImage} alt='1' />
                                    </div>
                                    <Fragment>
                                        {(secondaryImages.length > 0) ?
                                            <Fragment>
                                                <div className='secondary'>
                                                    {secondaryImages.map((image) => {
                                                        return (
                                                            <img key={image} src={image} alt='1' />
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </Fragment>
                                            : <Fragment />
                                        }

                                    </Fragment>
                                </Fragment>
                            }
                        </Fragment>

                        :
                        <div> No Images Yet </div>
                }
            </div>
            <Dialog
                header="Photos"
                footer
                visible={showGalleryOverlay}
                onHide={hideAll}
                className='photo-overlay'
            >
                <Galleria
                    value={props.images}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                    numVisible={8}
                    style={{ height: '100%' }}
                />
            </Dialog>
        </Fragment>
    )

}