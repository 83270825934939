

export const customListReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        comments
        tags
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        author
        description
        place {
                address
                lat
                lng
                name
            }
            rating {
                value
                overall
                food
                exp
            }
      }
      nextToken
      startedAt
    }
  }
`;

export const customGetReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
        comments
        tags
        createdAt
        updatedAt
        author
        description
        place {
                address
                lat
                lng
                name
            }
            rating {
                value
                overall
                food
                exp
            }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;