import { StarRating, StarRatingWithNumber } from "../shared/Review/StarRatings";
import { Card } from 'primereact/card';
import { Fragment, useEffect } from "react";

import placeholderImage from "../../assets/images/placeholder_food.jpg"
import { isMobile } from "react-device-detect";

import aliceAvatar from "../../assets/aliceAvatar.jpg"
import samAvatar from "../../assets/samAvatar.jpg"

const getAvatar = (person) => {
    person = person.toUpperCase().trim()
    if (person === 'ALICE') {
        return (
            <img className='avatar' src={aliceAvatar}/>
        )
    } else if (person === 'SAM') {
        return (
            <img className='avatar' src={samAvatar}/>
        )
    }

}

const avatars = ['SAM', 'ALICE']

const BlogCard = (props) => {

    const review = props.review
    const imageDict = props.imageDict

    const id = review.id
    const image = (id in imageDict) ? imageDict[id] : placeholderImage
    const header = <img alt="Card" src={image} />;

    // console.log(imageDict['6bfc6b23-da54-456a-acb9-d63f916444da'])

    // console.log('rev', review)

    const goToPost = (e) => {
        e.preventDefault();
        window.location = `/reviews/${review.id}`;
    }

    return (
        <Card
        className={`${(isMobile) ? 'blog-post mobile' : 'blog-post'}`}
            title={<div style={{position: 'relative'}}>
                <div className='title-text'>{review.title}</div>
                <Fragment>
                    {(review.author !== null) ?
                        <div className="author-row">
                            {review.author.map((person) => {
                                // console.log(person.toUpperCase().trim())
                                if (avatars.includes(person.toUpperCase().trim())) {
                                    return getAvatar(person)
                                } else{
                                    return (<div key={`authorIcon_${person}`} className='author-icon'>{person[0].toUpperCase()}</div>)
                                }
                            }
                            )}
                        </div>
                        : <div></div>
                    }
                </Fragment>
            </div>}
            subTitle={`${review.place.address.split(',')[1]}, ${review.place.address.split(',')[2].split(" ").slice(0,-1).join(" ")}`}
            header={header}
            onClick={goToPost}
        >
            {/* <h2 className='title'>{review.place.name}</h2> */}
            {/* <Divider/> */}
            <div className='rating-section'>
                <div className='overall-rating'>
                    <div className='rating'><p className='label'>OVERALL</p> <div className='metric'>{review.rating.overall.toFixed(1)}</div>{StarRating(review.rating.overall)}</div>
                </div>
                <div className='star-ratings'>
                    <div className='rating'><p className='label'>FOOD</p> {StarRating(review.rating.food)}</div>
                    <div className='rating'><p className='label'>VALUE</p> {StarRating(review.rating.value)}</div>
                    <div className='rating'><p className='label'>EXPERIENCE</p> {StarRating(review.rating.exp)}</div>
                </div>
            </div>
            <p className='short-desc'> "{review.description}" </p>
            {/* <Divider style={{backgroundColor:'#000'}}/> */}
            <div className='tag-section'>
                <p className='label'>TAGS</p>
                {review.tags.map((tag) => {
                    return (
                        <div className='tag' key={tag}>{tag.toUpperCase()}</div>
                    )
                })}
            </div>
            {/* <Divider/> */}
            {/* <div className='comments-section'>
                <p className='label'>REVIEW</p>
                <div style={{ margin: '25px', textAlign: 'middle' }}><td dangerouslySetInnerHTML={{ __html: review.comments }} /></div>
            </div> */}
        </Card>
    )
}

export default BlogCard;