import './App.css';
import Header from './components/shared/header'
import Footer from './components/shared/footer'
import { Helmet } from "react-helmet";

// import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

import AppRoutes from './Routes';

import './assets/custom-bear.css'
import './components/shared/shared.css'

function App() {
  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Roboto:wght@100&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Nunito&family=Permanent+Marker&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Comfortaa&display=swap" rel="stylesheet"></link>
      <script src="https://kit.fontawesome.com/4faefdf1a4.js" crossOrigin="anonymous"></script>
      
      <Helmet>
        <style>{"body { background-color: #f5efe0; }"}</style>
      </Helmet>

      <Header />
      <Footer />
      <AppRoutes/>
    </div>

  );
}

export default App;
