import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StarRating } from '../shared/Review/StarRatings'

let foodRubric = [
    {number: 5,    description: 'Spectacular, No to very little complaints. Food that is worth experiencing and coming back to.'    },
    {number: 4,    description: 'Good, Above Average, Some miscues. Food that is worth trying if you can'    },
    {number: 3,    description: 'Average, Nothing Special. Take it or leave it.'    },
    {number: 2,    description: 'Below Average, Food that you should only have if you are desperate'    },
    {number: 1,    description: 'Awful. Do not eat here.'    },
    ]

let valueRubric = [
    {number: 5,    description: 'Worth the money and possible more. A great deal for the quality or quanity of food.'    },
    {number: 4,    description: 'Decently priced. Fits in to what you would expect based on the quality or quantity of food'    },
    {number: 3,    description: 'Average pricing. No shockers.'    },
    {number: 2,    description: 'Overpriced for what you get'    },
    {number: 1,    description: 'Significantly overpriced for the quality or quantity of food you get'    },
    ]

let expRubric = [
    {number: 5,    description: 'Spectacular dining experience. Excellent Service. Nice Ambience. Clean.'    },
    {number: 4,    description: 'Comfortable dining experience. Good Service. What you would expect from a self-respecting restarant or venue'    },
    {number: 3,    description: 'Acceptable dining experience. Ambience or service is not a priority.'    },
    {number: 2,    description: 'Below Average dining experience. Very Rudimentary service'    },
    {number: 1,    description: 'Bad Service or unclean. Possible health hazards'    },
    ]
    

const starTemplate = (rowData) => {
    return <span>{StarRating(rowData.number)}</span>;
}


const AboutPage = () => {

    return (
        <div style={{width: '66%', marginLeft:'auto',  marginRight: 'auto'}}>
            <h3>Food</h3>
            <p style={{textAlign: 'left'}}>We want to rate the food first and foremost. Flavor, texture, creativity, uniqueness, all factor into our rating. Food also gets 3x the weight into our overall score calculation</p>
            <DataTable 
                showGridlines
                value={foodRubric} 
                responsiveLayout="scroll">
                <Column field="number" header="Rating" body={starTemplate} style={{ minWidth: '150px' }}></Column>
                <Column field="description" header="Criteria"></Column>
            </DataTable>

            <h3>Value</h3>
            <p style={{textAlign: 'left'}}>This rating indicates what we think of the prices in relation to the quality/quantity of food</p>
            <DataTable 
                showGridlines
                value={valueRubric} 
                responsiveLayout="scroll">
                <Column field="number" header="Rating" body={starTemplate} style={{ minWidth: '150px' }}></Column>
                <Column field="description" header="Criteria"></Column>
            </DataTable>

            <h3>Experience</h3>
            <p style={{textAlign: 'left'}}>This rating encompasses all the other factors when judging a dining experience. Service, waittime, cleanliness, ambience, all play a part in this rating.</p>
            <DataTable 
                showGridlines
                value={expRubric} 
                responsiveLayout="scroll">
                <Column field="number" header="Rating" body={starTemplate} style={{ minWidth: '150px' }}></Column>
                <Column field="description" header="Criteria"></Column>
            </DataTable>
            <br/>
            <br/>
            <br/>
        </div>
    )


}

export default AboutPage;